export const datasetCodes = [
  {country: 'Afghanistan', iso3Code: 'AFG', iso2Code: 'AF', datasetCode: 'af-address-ed', searchType: 'singleline, validate'},
  {country: 'Afghanistan', iso3Code: 'AFG', iso2Code: 'AF', datasetCode: 'af-address-eh', searchType: 'autocomplete'},
  {country: 'Albania', iso3Code: 'ALB', iso2Code: 'AL', datasetCode: 'al-address-ed', searchType: 'singleline, validate'},
  {country: 'Albania', iso3Code: 'ALB', iso2Code: 'AL', datasetCode: 'al-address-eh', searchType: 'autocomplete'},
  {country: 'Algeria', iso3Code: 'DZA', iso2Code: 'DZ', datasetCode: 'dz-address-ed', searchType: 'singleline, validate'},
  {country: 'Algeria', iso3Code: 'DZA', iso2Code: 'DZ', datasetCode: 'dz-address-eh', searchType: 'autocomplete'},
  {country: 'American Samoa', iso3Code: 'ASM', iso2Code: 'AS', datasetCode: 'as-address-eh', searchType: 'autocomplete'},
  {country: 'Andorra', iso3Code: 'AND', iso2Code: 'AD', datasetCode: 'ad-address-ed', searchType: 'singleline, validate'},
  {country: 'Andorra', iso3Code: 'AND', iso2Code: 'AD', datasetCode: 'ad-address-eh', searchType: 'autocomplete'},
  {country: 'Angola', iso3Code: 'AGO', iso2Code: 'AO', datasetCode: 'ao-address-ed', searchType: 'singleline, validate'},
  {country: 'Angola', iso3Code: 'AGO', iso2Code: 'AO', datasetCode: 'ao-address-eh', searchType: 'autocomplete'},
  {country: 'Anguilla', iso3Code: 'AIA', iso2Code: 'AI', datasetCode: 'ai-address-eh', searchType: 'autocomplete'},
  {country: 'Antarctica', iso3Code: 'ATA', iso2Code: 'AQ', datasetCode: 'aq-address-ed', searchType: 'singleline, validate'},
  {country: 'Antigua and Barbuda', iso3Code: 'ATG', iso2Code: 'AG', datasetCode: 'ag-address-ed', searchType: 'singleline, validate'},
  {country: 'Antigua and Barbuda', iso3Code: 'ATG', iso2Code: 'AG', datasetCode: 'ag-address-eh', searchType: 'autocomplete'},
  {country: 'Argentina', iso3Code: 'ARG', iso2Code: 'AR', datasetCode: 'ar-address-ed', searchType: 'singleline, validate'},
  {country: 'Argentina', iso3Code: 'ARG', iso2Code: 'AR', datasetCode: 'ar-address-eh', searchType: 'autocomplete'},
  {country: 'Armenia', iso3Code: 'ARM', iso2Code: 'AM', datasetCode: 'am-address-ed', searchType: 'singleline, validate'},
  {country: 'Armenia', iso3Code: 'ARM', iso2Code: 'AM', datasetCode: 'am-address-eh', searchType: 'autocomplete'},
  {country: 'Aruba', iso3Code: 'ABW', iso2Code: 'AW', datasetCode: 'aw-address-ed', searchType: 'singleline, validate'},
  {country: 'Aruba', iso3Code: 'ABW', iso2Code: 'AW', datasetCode: 'aw-address-eh', searchType: 'autocomplete'},
  {country: 'Australia DataFusion', iso3Code: 'AUS', iso2Code: 'AU', datasetCode: 'au-address-datafusion', searchType: 'autocomplete, singleline, typedown'},
  {country: 'Australia Geocoded National Address', iso3Code: 'AUS', iso2Code: 'AU', datasetCode: 'au-address-gnaf', searchType: 'autocomplete, singleline, typedown, validate'},
  {country: 'Australia Postal Address', iso3Code: 'AUS', iso2Code: 'AU', datasetCode: 'au-address', searchType: 'autocomplete, singleline, typedown, validate'},
  {country: 'Austria', iso3Code: 'AUT', iso2Code: 'AT', datasetCode: 'at-address-eh', searchType: 'autocomplete'},
  {country: 'Austria', iso3Code: 'AUT', iso2Code: 'AT', datasetCode: 'az-address-ed', searchType: 'singleline, validate'},
  {country: 'Azerbaijan', iso3Code: 'AZE', iso2Code: 'AZ', datasetCode: 'az-address-ed', searchType: 'singleline, validate'},
  {country: 'Azerbaijan', iso3Code: 'AZE', iso2Code: 'AZ', datasetCode: 'az-address-eh', searchType: 'autocomplete'},
  {country: 'Bahamas', iso3Code: 'BHS', iso2Code: 'BS', datasetCode: 'bs-address-ed', searchType: 'singleline, validate'},
  {country: 'Bahamas', iso3Code: 'BHS', iso2Code: 'BS', datasetCode: 'bs-address-eh', searchType: 'autocomplete'},
  {country: 'Bahrain', iso3Code: 'BHR', iso2Code: 'BH', datasetCode: 'bh-address-ed', searchType: 'singleline, validate'},
  {country: 'Bahrain', iso3Code: 'BHR', iso2Code: 'BH', datasetCode: 'bh-address-eh', searchType: 'autocomplete'},
  {country: 'Bangladesh', iso3Code: 'BGD', iso2Code: 'BD', datasetCode: 'bd-address-ed', searchType: 'singleline, validate'},
  {country: 'Bangladesh', iso3Code: 'BGD', iso2Code: 'BD', datasetCode: 'bd-address-eh', searchType: 'autocomplete'},
  {country: 'Barbados', iso3Code: 'BRB', iso2Code: 'BB', datasetCode: 'bb-address-ed', searchType: 'singleline, validate'},
  {country: 'Barbados', iso3Code: 'BRB', iso2Code: 'BB', datasetCode: 'bb-address-eh', searchType: 'autocomplete'},
  {country: 'Belarus', iso3Code: 'BLR', iso2Code: 'BY', datasetCode: 'by-address-ed', searchType: 'singleline, validate'},
  {country: 'Belarus', iso3Code: 'BLR', iso2Code: 'BY', datasetCode: 'by-address-eh', searchType: 'autocomplete'},
  {country: 'Belgium', iso3Code: 'BEL', iso2Code: 'BE', datasetCode: 'be-address', searchType: 'singleline, typedown'},
  {country: 'Belgium', iso3Code: 'BEL', iso2Code: 'BE', datasetCode: 'be-address-ed', searchType: ' validate'},
  {country: 'Belgium', iso3Code: 'BEL', iso2Code: 'BE', datasetCode: 'be-address-eh', searchType: 'autocomplete'},
  {country: 'Belize', iso3Code: 'BLZ', iso2Code: 'BZ', datasetCode: 'bz-address-ed', searchType: 'singleline, validate'},
  {country: 'Belize', iso3Code: 'BLZ', iso2Code: 'BZ', datasetCode: 'bz-address-eh', searchType: 'autocomplete'},
  {country: 'Benin', iso3Code: 'BEN', iso2Code: 'BJ', datasetCode: 'bj-address-ed', searchType: 'singleline, validate'},
  {country: 'Benin', iso3Code: 'BEN', iso2Code: 'BJ', datasetCode: 'bj-address-eh', searchType: 'autocomplete'},
  {country: 'Bermuda', iso3Code: 'BMU', iso2Code: 'BM', datasetCode: 'bm-address-ed', searchType: 'singleline, validate'},
  {country: 'Bermuda', iso3Code: 'BMU', iso2Code: 'BM', datasetCode: 'bm-address-eh', searchType: 'autocomplete'},
  {country: 'Bhutan', iso3Code: 'BTN', iso2Code: 'BT', datasetCode: 'bt-address-ed', searchType: 'singleline, validate'},
  {country: 'Bhutan', iso3Code: 'BTN', iso2Code: 'BT', datasetCode: 'bt-address-eh', searchType: 'autocomplete'},
  {country: 'Bolivia', iso3Code: 'BOL', iso2Code: 'BO', datasetCode: 'bo-address-ed', searchType: 'singleline, validate'},
  {country: 'Bolivia', iso3Code: 'BOL', iso2Code: 'BO', datasetCode: 'bo-address-eh', searchType: 'autocomplete'},
  {country: 'Bonaire, Sint Eustatius and Saba', iso3Code: 'BES', iso2Code: 'BQ', datasetCode: 'bq-address-ed', searchType: 'singleline, validate'},
  {country: 'Bonaire, Sint Eustatius and Saba', iso3Code: 'BES', iso2Code: 'BQ', datasetCode: 'bq-address-eh', searchType: 'autocomplete'},
  {country: 'Bosnia and Herzegovina', iso3Code: 'BIH', iso2Code: 'BA', datasetCode: 'ba-address-ed', searchType: 'singleline, validate'},
  {country: 'Bosnia and Herzegovina', iso3Code: 'BIH', iso2Code: 'BA', datasetCode: 'ba-address-eh', searchType: 'autocomplete'},
  {country: 'Botswana', iso3Code: 'BWA', iso2Code: 'BW', datasetCode: 'bw-address-ed', searchType: 'singleline, validate'},
  {country: 'Botswana', iso3Code: 'BWA', iso2Code: 'BW', datasetCode: 'bw-address-eh', searchType: 'autocomplete'},
  {country: 'Brazil', iso3Code: 'BRA', iso2Code: 'BR', datasetCode: 'br-address-ed', searchType: 'singleline, validate'},
  {country: 'Brazil', iso3Code: 'BRA', iso2Code: 'BR', datasetCode: 'br-address-eh', searchType: 'autocomplete'},
  {country: 'Brunei Darussalam', iso3Code: 'BRN', iso2Code: 'BN', datasetCode: 'bn-address-ed', searchType: 'singleline, validate'},
  {country: 'Brunei Darussalam', iso3Code: 'BRN', iso2Code: 'BN', datasetCode: 'bn-address-eh', searchType: 'autocomplete'},
  {country: 'Bulgaria', iso3Code: 'BGR', iso2Code: 'BG', datasetCode: 'bg-address-ed', searchType: 'singleline, validate'},
  {country: 'Bulgaria', iso3Code: 'BGR', iso2Code: 'BG', datasetCode: 'bg-address-eh', searchType: 'autocomplete'},
  {country: 'Burkina Faso', iso3Code: 'BFA', iso2Code: 'BF', datasetCode: 'bf-address-ed', searchType: 'singleline, validate'},
  {country: 'Burkina Faso', iso3Code: 'BFA', iso2Code: 'BF', datasetCode: 'bf-address-eh', searchType: 'autocomplete'},
  {country: 'Burundi', iso3Code: 'BDI', iso2Code: 'BI', datasetCode: 'bi-address-eh', searchType: 'autocomplete'},
  {country: 'Cambodia', iso3Code: 'KHM', iso2Code: 'KH', datasetCode: 'kh-address-ed', searchType: 'singleline, validate'},
  {country: 'Cambodia', iso3Code: 'KHM', iso2Code: 'KH', datasetCode: 'kh-address-eh', searchType: 'autocomplete'},
  {country: 'Cameroon', iso3Code: 'CMR', iso2Code: 'CM', datasetCode: 'cm-address-ed', searchType: 'singleline, validate'},
  {country: 'Cameroon', iso3Code: 'CMR', iso2Code: 'CM', datasetCode: 'cm-address-eh', searchType: 'autocomplete'},
  {country: 'Canada', iso3Code: 'CAN', iso2Code: 'CA', datasetCode: 'ca-address', searchType: 'autocomplete, singleline, typedown, validate'},
  {country: 'Cape Verde', iso3Code: 'CPV', iso2Code: 'CV', datasetCode: 'cv-address-ed', searchType: 'singleline, validate'},
  {country: 'Cape Verde', iso3Code: 'CPV', iso2Code: 'CV', datasetCode: 'cv-address-eh', searchType: 'autocomplete'},
  {country: 'Cayman Islands', iso3Code: 'CYM', iso2Code: 'KY', datasetCode: 'ky-address-ed', searchType: 'singleline, validate'},
  {country: 'Cayman Islands', iso3Code: 'CYM', iso2Code: 'KY', datasetCode: 'ky-address-eh', searchType: 'autocomplete'},
  {country: 'Central African Republic', iso3Code: 'CAF', iso2Code: 'CF', datasetCode: 'cf-address-ed', searchType: 'singleline, validate'},
  {country: 'Central African Republic', iso3Code: 'CAF', iso2Code: 'CF', datasetCode: 'cf-address-eh', searchType: 'autocomplete'},
  {country: 'Chad', iso3Code: 'TCD', iso2Code: 'TD', datasetCode: 'td-address-ed', searchType: 'singleline, validate'},
  {country: 'Chad', iso3Code: 'TCD', iso2Code: 'TD', datasetCode: 'td-address-eh', searchType: 'autocomplete'},
  {country: 'Chile', iso3Code: 'CHL', iso2Code: 'CL', datasetCode: 'cl-address-ed', searchType: 'singleline, validate'},
  {country: 'Chile', iso3Code: 'CHL', iso2Code: 'CL', datasetCode: 'cl-address-eh', searchType: 'autocomplete'},
  {country: 'China', iso3Code: 'CHN', iso2Code: 'CN', datasetCode: 'cn-address-ed', searchType: 'singleline, validate'},
  {country: 'China', iso3Code: 'CHN', iso2Code: 'CN', datasetCode: 'cn-address-eh', searchType: 'autocomplete'},
  {country: 'Christmas Island', iso3Code: 'CXR', iso2Code: 'CX', datasetCode: 'cx-address-eh', searchType: 'autocomplete'},
  {country: 'Cocos Island', iso3Code: 'CCK', iso2Code: 'CC', datasetCode: 'cc-address-eh', searchType: 'autocomplete'},
  {country: 'Colombia', iso3Code: 'COL', iso2Code: 'CO', datasetCode: 'co-address-ed', searchType: 'singleline, validate'},
  {country: 'Colombia', iso3Code: 'COL', iso2Code: 'CO', datasetCode: 'co-address-eh', searchType: 'autocomplete'},
  {country: 'Comoros', iso3Code: 'COM', iso2Code: 'KM', datasetCode: 'km-address-ed', searchType: 'singleline, validate'},
  {country: 'Comoros', iso3Code: 'COM', iso2Code: 'KM', datasetCode: 'km-address-eh', searchType: 'autocomplete'},
  {country: 'Congo', iso3Code: 'COG', iso2Code: 'CG', datasetCode: 'cg-address-ed', searchType: 'singleline, validate'},
  {country: 'Congo', iso3Code: 'COG', iso2Code: 'CG', datasetCode: 'cg-address-eh', searchType: 'autocomplete'},
  {country: 'Congo, The Democratic Republic of the', iso3Code: 'COD', iso2Code: 'CD', datasetCode: 'cd-address-ed', searchType: 'singleline, validate'},
  {country: 'Congo, The Democratic Republic of the', iso3Code: 'COD', iso2Code: 'CD', datasetCode: 'cd-address-eh', searchType: 'autocomplete'},
  {country: 'Cook Islands', iso3Code: 'COK', iso2Code: 'CK', datasetCode: 'ck-address-ed', searchType: 'singleline, validate'},
  {country: 'Cook Islands', iso3Code: 'COK', iso2Code: 'CK', datasetCode: 'ck-address-eh', searchType: 'autocomplete'},
  {country: 'Costa Rica', iso3Code: 'CRI', iso2Code: 'CR', datasetCode: 'cr-address-ed', searchType: 'singleline, validate'},
  {country: 'Costa Rica', iso3Code: 'CRI', iso2Code: 'CR', datasetCode: 'cr-address-eh', searchType: 'autocomplete'},
  {country: 'Croatia', iso3Code: 'HRV', iso2Code: 'HR', datasetCode: 'hr-address-ed', searchType: 'singleline, validate'},
  {country: 'Croatia', iso3Code: 'HRV', iso2Code: 'HR', datasetCode: 'hr-address-eh', searchType: 'autocomplete'},
  {country: 'Cuba', iso3Code: 'CUB', iso2Code: 'CU', datasetCode: 'cu-address-ed', searchType: 'singleline, validate'},
  {country: 'Cuba', iso3Code: 'CUB', iso2Code: 'CU', datasetCode: 'cu-address-eh', searchType: 'autocomplete'},
  {country: 'Curaçao', iso3Code: 'CUW', iso2Code: 'CW', datasetCode: 'cw-address-ed', searchType: 'singleline, validate'},
  {country: 'Curaçao', iso3Code: 'CUW', iso2Code: 'CW', datasetCode: 'cw-address-eh', searchType: 'autocomplete'},
  {country: 'Cyprus', iso3Code: 'CYP', iso2Code: 'CY', datasetCode: 'cy-address-ed', searchType: 'singleline, validate'},
  {country: 'Cyprus', iso3Code: 'CYP', iso2Code: 'CY', datasetCode: 'cy-address-eh', searchType: 'autocomplete'},
  {country: 'Czech Republic', iso3Code: 'CZE', iso2Code: 'CZ', datasetCode: 'cz-address-ed', searchType: 'singleline, validate'},
  {country: 'Czech Republic', iso3Code: 'CZE', iso2Code: 'CZ', datasetCode: 'cz-address-eh', searchType: 'autocomplete'},
  {country: 'Côte d\'Ivoire', iso3Code: 'CIV', iso2Code: 'CI', datasetCode: 'ci-address-ed', searchType: 'singleline, validate'},
  {country: 'Côte d\'Ivoire', iso3Code: 'CIV', iso2Code: 'CI', datasetCode: 'ci-address-eh', searchType: 'autocomplete'},
  {country: 'Denmark', iso3Code: 'DNK', iso2Code: 'DK', datasetCode: 'dk-address-ed', searchType: 'singleline, validate'},
  {country: 'Denmark', iso3Code: 'DNK', iso2Code: 'DK', datasetCode: 'dk-address-eh', searchType: 'autocomplete'},
  {country: 'Djibouti', iso3Code: 'DJI', iso2Code: 'DJ', datasetCode: 'dj-address-ed', searchType: 'singleline, validate'},
  {country: 'Djibouti', iso3Code: 'DJI', iso2Code: 'DJ', datasetCode: 'dj-address-eh', searchType: 'autocomplete'},
  {country: 'Dominica', iso3Code: 'DMA', iso2Code: 'DM', datasetCode: 'dm-address-ed', searchType: 'singleline, validate'},
  {country: 'Dominica', iso3Code: 'DMA', iso2Code: 'DM', datasetCode: 'dm-address-eh', searchType: 'autocomplete'},
  {country: 'Dominican Republic', iso3Code: 'DOM', iso2Code: 'DO', datasetCode: 'do-address-ed', searchType: 'singleline, validate'},
  {country: 'Dominican Republic', iso3Code: 'DOM', iso2Code: 'DO', datasetCode: 'do-address-eh', searchType: 'autocomplete'},
  {country: 'Ecuador', iso3Code: 'ECU', iso2Code: 'EC', datasetCode: 'ec-address-ed', searchType: 'singleline, validate'},
  {country: 'Ecuador', iso3Code: 'ECU', iso2Code: 'EC', datasetCode: 'ec-address-eh', searchType: 'autocomplete'},
  {country: 'Egypt', iso3Code: 'EGY', iso2Code: 'EG', datasetCode: 'eg-address-ed', searchType: 'singleline, validate'},
  {country: 'Egypt', iso3Code: 'EGY', iso2Code: 'EG', datasetCode: 'eg-address-eh', searchType: 'autocomplete'},
  {country: 'El Salvador', iso3Code: 'SLV', iso2Code: 'SV', datasetCode: 'sv-address-ed', searchType: 'singleline, validate'},
  {country: 'El Salvador', iso3Code: 'SLV', iso2Code: 'SV', datasetCode: 'sv-address-eh', searchType: 'autocomplete'},
  {country: 'Equatorial Guinea', iso3Code: 'GNQ', iso2Code: 'GQ', datasetCode: 'gq-address-ed', searchType: 'singleline, validate'},
  {country: 'Equatorial Guinea', iso3Code: 'GNQ', iso2Code: 'GQ', datasetCode: 'gq-address-eh', searchType: 'autocomplete'},
  {country: 'Eritrea', iso3Code: 'ERI', iso2Code: 'ER', datasetCode: 'er-address-ed', searchType: 'singleline, validate'},
  {country: 'Eritrea', iso3Code: 'ERI', iso2Code: 'ER', datasetCode: 'er-address-eh', searchType: 'autocomplete'},
  {country: 'Estonia', iso3Code: 'EST', iso2Code: 'EE', datasetCode: 'ee-address-ed', searchType: 'singleline, validate'},
  {country: 'Estonia', iso3Code: 'EST', iso2Code: 'EE', datasetCode: 'ee-address-eh', searchType: 'autocomplete'},
  {country: 'Ethiopia', iso3Code: 'ETH', iso2Code: 'ET', datasetCode: 'et-address-ed', searchType: 'singleline, validate'},
  {country: 'Ethiopia', iso3Code: 'ETH', iso2Code: 'ET', datasetCode: 'et-address-eh', searchType: 'autocomplete'},
  {country: 'Falkland Islands (Malvinas)', iso3Code: 'FLK', iso2Code: 'FK', datasetCode: 'fk-address-eh', searchType: 'autocomplete'},
  {country: 'Faroe Islands', iso3Code: 'FRO', iso2Code: 'FO', datasetCode: 'fo-address-ed', searchType: 'singleline, validate'},
  {country: 'Faroe Islands', iso3Code: 'FRO', iso2Code: 'FO', datasetCode: 'fo-address-eh', searchType: 'autocomplete'},
  {country: 'Fiji', iso3Code: 'FJI', iso2Code: 'FJ', datasetCode: 'fj-address-ed', searchType: 'singleline, validate'},
  {country: 'Fiji', iso3Code: 'FJI', iso2Code: 'FJ', datasetCode: 'fj-address-eh', searchType: 'autocomplete'},
  {country: 'Finland', iso3Code: 'FIN', iso2Code: 'FI', datasetCode: 'fi-address', searchType: 'singleline, typedown'},
  {country: 'Finland', iso3Code: 'FIN', iso2Code: 'FI', datasetCode: 'fi-address-ed', searchType: 'validate'},
  {country: 'Finland', iso3Code: 'FIN', iso2Code: 'FI', datasetCode: 'fi-address-eh', searchType: 'autocomplete'},
  {country: 'France Street Level', iso3Code: 'FRA', iso2Code: 'FR', datasetCode: 'fr-address-streetlevel', searchType: 'singleline, typedown, validate'},
  {country: 'France Sub-Premises Level', iso3Code: 'FRA', iso2Code: 'FR', datasetCode: 'fr-additional-hexaline3', searchType: 'autocomplete, singleline, typedown'},
  {country: 'France Premises Level', iso3Code: 'FRA', iso2Code: 'FR', datasetCode: 'fr-address', searchType: 'singleline, typedown, validate'},
  {country: 'French Guiana', iso3Code: 'GUF', iso2Code: 'GF', datasetCode: 'gf-address-eh', searchType: 'autocomplete'},
  {country: 'French Polynesia', iso3Code: 'PYF', iso2Code: 'PF', datasetCode: 'pf-address-eh', searchType: 'autocomplete'},
  {country: 'Gabon', iso3Code: 'GAB', iso2Code: 'GA', datasetCode: 'ga-address-ed', searchType: 'singleline, validate'},
  {country: 'Gabon', iso3Code: 'GAB', iso2Code: 'GA', datasetCode: 'ga-address-eh', searchType: 'autocomplete'},
  {country: 'Gambia', iso3Code: 'GMB', iso2Code: 'GM', datasetCode: 'gm-address-ed', searchType: 'singleline, validate'},
  {country: 'Gambia', iso3Code: 'GMB', iso2Code: 'GM', datasetCode: 'gm-address-eh', searchType: 'autocomplete'},
  {country: 'Georgia', iso3Code: 'GEO', iso2Code: 'GE', datasetCode: 'ge-address-ed', searchType: 'singleline, validate'},
  {country: 'Georgia', iso3Code: 'GEO', iso2Code: 'GE', datasetCode: 'ge-address-eh', searchType: 'autocomplete'},
  {country: 'Germany', iso3Code: 'DEU', iso2Code: 'DE', datasetCode: 'de-address', searchType: 'singleline, typedown, validate'},
  {country: 'Germany', iso3Code: 'DEU', iso2Code: 'DE', datasetCode: 'de-address-ed', searchType: 'validate'},
  {country: 'Germany', iso3Code: 'DEU', iso2Code: 'DE', datasetCode: 'de-address-eh', searchType: 'autocomplete'},
  {country: 'Ghana', iso3Code: 'GHA', iso2Code: 'GH', datasetCode: 'gh-address-ed', searchType: 'singleline, validate'},
  {country: 'Ghana', iso3Code: 'GHA', iso2Code: 'GH', datasetCode: 'gh-address-eh', searchType: 'autocomplete'},
  {country: 'Gibraltar', iso3Code: 'GIB', iso2Code: 'GI', datasetCode: 'gi-address-eh', searchType: 'autocomplete'},
  {country: 'Greece', iso3Code: 'GRC', iso2Code: 'GR', datasetCode: 'gr-address-ed', searchType: 'singleline, validate'},
  {country: 'Greece', iso3Code: 'GRC', iso2Code: 'GR', datasetCode: 'gr-address-eh', searchType: 'autocomplete'},
  {country: 'Greenland', iso3Code: 'GRL', iso2Code: 'GL', datasetCode: 'gl-address-ed', searchType: 'singleline, validate'},
  {country: 'Greenland', iso3Code: 'GRL', iso2Code: 'GL', datasetCode: 'gl-address-eh', searchType: 'autocomplete'},
  {country: 'Grenada', iso3Code: 'GRD', iso2Code: 'GD', datasetCode: 'gd-address-ed', searchType: 'singleline, validate'},
  {country: 'Grenada', iso3Code: 'GRD', iso2Code: 'GD', datasetCode: 'gd-address-eh', searchType: 'autocomplete'},
  {country: 'Guadeloupe', iso3Code: 'GLP', iso2Code: 'GP', datasetCode: 'gp-address-eh', searchType: 'autocomplete'},
  {country: 'Guam', iso3Code: 'GUM', iso2Code: 'GU', datasetCode: 'gu-address-eh', searchType: 'autocomplete'},
  {country: 'Guatemala', iso3Code: 'GTM', iso2Code: 'GT', datasetCode: 'gt-address-ed', searchType: 'singleline, validate'},
  {country: 'Guatemala', iso3Code: 'GTM', iso2Code: 'GT', datasetCode: 'gt-address-eh', searchType: 'autocomplete'},
  {country: 'Guinea', iso3Code: 'GIN', iso2Code: 'GN', datasetCode: 'gn-address-ed', searchType: 'singleline, validate'},
  {country: 'Guinea', iso3Code: 'GIN', iso2Code: 'GN', datasetCode: 'gn-address-eh', searchType: 'autocomplete'},
  {country: 'Guinea-Bissau', iso3Code: 'GNB', iso2Code: 'GW', datasetCode: 'gw-address-ed', searchType: 'singleline, validate'},
  {country: 'Guinea-Bissau', iso3Code: 'GNB', iso2Code: 'GW', datasetCode: 'gw-address-eh', searchType: 'autocomplete'},
  {country: 'Guyana', iso3Code: 'GUY', iso2Code: 'GY', datasetCode: 'gy-address-ed', searchType: 'singleline, validate'},
  {country: 'Guyana', iso3Code: 'GUY', iso2Code: 'GY', datasetCode: 'gy-address-eh', searchType: 'autocomplete'},
  {country: 'Haiti', iso3Code: 'HTI', iso2Code: 'HT', datasetCode: 'ht-address-ed', searchType: 'singleline, validate'},
  {country: 'Haiti', iso3Code: 'HTI', iso2Code: 'HT', datasetCode: 'ht-address-eh', searchType: 'autocomplete'},
  {country: 'Honduras', iso3Code: 'HND', iso2Code: 'HN', datasetCode: 'hn-address-ed', searchType: 'singleline, validate'},
  {country: 'Honduras', iso3Code: 'HND', iso2Code: 'HN', datasetCode: 'hn-address-eh', searchType: 'autocomplete'},
  {country: 'Hong Kong', iso3Code: 'HKG', iso2Code: 'HK', datasetCode: 'hk-address-ed', searchType: 'singleline, validate'},
  {country: 'Hong Kong', iso3Code: 'HKG', iso2Code: 'HK', datasetCode: 'hk-address-eh', searchType: 'autocomplete'},
  {country: 'Hungary', iso3Code: 'HUN', iso2Code: 'HU', datasetCode: 'hu-address-ed', searchType: 'singleline, validate'},
  {country: 'Hungary', iso3Code: 'HUN', iso2Code: 'HU', datasetCode: 'hu-address-eh', searchType: 'autocomplete'},
  {country: 'Iceland', iso3Code: 'ISL', iso2Code: 'IS', datasetCode: 'is-address-ed', searchType: 'singleline, validate'},
  {country: 'Iceland', iso3Code: 'ISL', iso2Code: 'IS', datasetCode: 'is-address-eh', searchType: 'autocomplete'},
  {country: 'India', iso3Code: 'IND', iso2Code: 'IN', datasetCode: 'in-address-ed', searchType: 'singleline, validate'},
  {country: 'India', iso3Code: 'IND', iso2Code: 'IN', datasetCode: 'in-address-eh', searchType: 'autocomplete'},
  {country: 'Indonesia', iso3Code: 'IDN', iso2Code: 'ID', datasetCode: 'id-address-ed', searchType: 'singleline, validate'},
  {country: 'Indonesia', iso3Code: 'IDN', iso2Code: 'ID', datasetCode: 'id-address-eh', searchType: 'autocomplete'},
  {country: 'Iran, Islamic Republic of', iso3Code: 'IRN', iso2Code: 'IR', datasetCode: 'ir-address-ed', searchType: 'singleline, validate'},
  {country: 'Iran, Islamic Republic of', iso3Code: 'IRN', iso2Code: 'IR', datasetCode: 'ir-address-eh', searchType: 'autocomplete'},
  {country: 'Iraq', iso3Code: 'IRQ', iso2Code: 'IQ', datasetCode: 'iq-address-ed', searchType: 'singleline, validate'},
  {country: 'Iraq', iso3Code: 'IRQ', iso2Code: 'IQ', datasetCode: 'iq-address-eh', searchType: 'autocomplete'},
  {country: 'Ireland', iso3Code: 'IRL', iso2Code: 'IE', datasetCode: 'ie-address', searchType: 'singleline, typedown'},
  {country: 'Ireland', iso3Code: 'IRL', iso2Code: 'IE', datasetCode: 'ie-address-ed', searchType: 'singleline, validate'},
  {country: 'Ireland', iso3Code: 'IRL', iso2Code: 'IE', datasetCode: 'ie-address-eh', searchType: 'autocomplete'},
  {country: 'Ireland Eircode', iso3Code: 'IRL', iso2Code: 'IE', datasetCode: 'ie-additional-eircode', searchType: 'autocomplete'},
  {country: 'Ireland ECAD', iso3Code: 'IRL', iso2Code: 'IE', datasetCode: 'ie-address-ecad', searchType: 'autocomplete'},
  {country: 'Israel', iso3Code: 'ISR', iso2Code: 'IL', datasetCode: 'il-address-ed', searchType: 'singleline, validate'},
  {country: 'Israel', iso3Code: 'ISR', iso2Code: 'IL', datasetCode: 'il-address-eh', searchType: 'autocomplete'},
  {country: 'Italy', iso3Code: 'ITA', iso2Code: 'IT', datasetCode: 'it-address-ed', searchType: 'singleline, validate'},
  {country: 'Italy', iso3Code: 'ITA', iso2Code: 'IT', datasetCode: 'it-address-eh', searchType: 'autocomplete'},
  {country: 'Jamaica', iso3Code: 'JAM', iso2Code: 'JM', datasetCode: 'jm-address-ed', searchType: 'singleline, validate'},
  {country: 'Jamaica', iso3Code: 'JAM', iso2Code: 'JM', datasetCode: 'jm-address-eh', searchType: 'autocomplete'},
  {country: 'Japan', iso3Code: 'JPN', iso2Code: 'JP', datasetCode: 'jp-address-ed', searchType: 'singleline, validate'},
  {country: 'Japan', iso3Code: 'JPN', iso2Code: 'JP', datasetCode: 'jp-address', searchType: 'singleline, validate'},
  {country: 'Jordan', iso3Code: 'JOR', iso2Code: 'JO', datasetCode: 'jo-address-ed', searchType: 'singleline, validate'},
  {country: 'Jordan', iso3Code: 'JOR', iso2Code: 'JO', datasetCode: 'jo-address-eh', searchType: 'autocomplete'},
  {country: 'Kazakhstan', iso3Code: 'KAZ', iso2Code: 'KZ', datasetCode: 'kz-address-ed', searchType: 'singleline, validate'},
  {country: 'Kazakhstan', iso3Code: 'KAZ', iso2Code: 'KZ', datasetCode: 'kz-address-eh', searchType: 'autocomplete'},
  {country: 'Kenya', iso3Code: 'KEN', iso2Code: 'KE', datasetCode: 'ke-address-ed', searchType: 'singleline, validate'},
  {country: 'Kenya', iso3Code: 'KEN', iso2Code: 'KE', datasetCode: 'ke-address-eh', searchType: 'autocomplete'},
  {country: 'Kiribati', iso3Code: 'KIR', iso2Code: 'KI', datasetCode: 'ki-address-ed', searchType: 'singleline, validate'},
  {country: 'Kiribati', iso3Code: 'KIR', iso2Code: 'KI', datasetCode: 'ki-address-eh', searchType: 'autocomplete'},
  {country: 'Korea, Democratic People\'s Republic of', iso3Code: 'PRK', iso2Code: 'KP', datasetCode: 'kp-address-ed', searchType: 'singleline, validate'},
  {country: 'Korea, Democratic People\'s Republic of', iso3Code: 'PRK', iso2Code: 'KP', datasetCode: 'kp-address-eh', searchType: 'autocomplete'},
  {country: 'Korea, Republic of', iso3Code: 'KOR', iso2Code: 'KR', datasetCode: 'kr-address-ed', searchType: 'singleline, validate'},
  {country: 'Korea, Republic of', iso3Code: 'KOR', iso2Code: 'KR', datasetCode: 'kr-address-eh', searchType: 'autocomplete'},
  {country: 'Kosovo', iso3Code: 'KOS', iso2Code: 'XK', datasetCode: 'kv-address-eh', searchType: 'autocomplete'},
  {country: 'Kuwait', iso3Code: 'KWT', iso2Code: 'KW', datasetCode: 'kw-address-ed', searchType: 'singleline, validate'},
  {country: 'Kuwait', iso3Code: 'KWT', iso2Code: 'KW', datasetCode: 'kw-address-eh', searchType: 'autocomplete'},
  {country: 'Kyrgyzstan', iso3Code: 'KGZ', iso2Code: 'KG', datasetCode: 'kg-address-ed', searchType: 'singleline, validate'},
  {country: 'Kyrgyzstan', iso3Code: 'KGZ', iso2Code: 'KG', datasetCode: 'kg-address-eh', searchType: 'autocomplete'},
  {country: 'Laos', iso3Code: 'LAO', iso2Code: 'LA', datasetCode: 'la-address-ed', searchType: 'singleline, validate'},
  {country: 'Laos', iso3Code: 'LAO', iso2Code: 'LA', datasetCode: 'la-address-eh', searchType: 'autocomplete'},
  {country: 'Latvia', iso3Code: 'LVA', iso2Code: 'LV', datasetCode: 'lv-address-ed', searchType: 'singleline, validate'},
  {country: 'Latvia', iso3Code: 'LVA', iso2Code: 'LV', datasetCode: 'lv-address-eh', searchType: 'autocomplete'},
  {country: 'Lebanon', iso3Code: 'LBN', iso2Code: 'LB', datasetCode: 'lb-address-ed', searchType: 'singleline, validate'},
  {country: 'Lebanon', iso3Code: 'LBN', iso2Code: 'LB', datasetCode: 'lb-address-eh', searchType: 'autocomplete'},
  {country: 'Lesotho', iso3Code: 'LSO', iso2Code: 'LS', datasetCode: 'ls-address-ed', searchType: 'singleline, validate'},
  {country: 'Lesotho', iso3Code: 'LSO', iso2Code: 'LS', datasetCode: 'ls-address-eh', searchType: 'autocomplete'},
  {country: 'Liberia', iso3Code: 'LBR', iso2Code: 'LR', datasetCode: 'lr-address-ed', searchType: 'singleline, validate'},
  {country: 'Liberia', iso3Code: 'LBR', iso2Code: 'LR', datasetCode: 'lr-address-eh', searchType: 'autocomplete'},
  {country: 'Libya', iso3Code: 'LBY', iso2Code: 'LY', datasetCode: 'ly-address-ed', searchType: 'singleline, validate'},
  {country: 'Libya', iso3Code: 'LBY', iso2Code: 'LY', datasetCode: 'ly-address-eh', searchType: 'autocomplete'},
  {country: 'Liechtenstein', iso3Code: 'LIE', iso2Code: 'LI', datasetCode: 'li-address-ed', searchType: 'singleline, validate'},
  {country: 'Liechtenstein', iso3Code: 'LIE', iso2Code: 'LI', datasetCode: 'li-address-eh', searchType: 'autocomplete'},
  {country: 'Lithuania', iso3Code: 'LTU', iso2Code: 'LT', datasetCode: 'lt-address-ed', searchType: 'singleline, validate'},
  {country: 'Lithuania', iso3Code: 'LTU', iso2Code: 'LT', datasetCode: 'lt-address-eh', searchType: 'autocomplete'},
  {country: 'Luxembourg', iso3Code: 'LUX', iso2Code: 'LU', datasetCode: 'lu-address-ed', searchType: 'singleline, validate'},
  {country: 'Luxembourg', iso3Code: 'LUX', iso2Code: 'LU', datasetCode: 'lu-address-eh', searchType: 'autocomplete'},
  {country: 'Macau', iso3Code: 'MAC', iso2Code: 'MO', datasetCode: 'lv-address-ed', searchType: 'singleline, validate'},
  {country: 'Macau', iso3Code: 'MAC', iso2Code: 'MO', datasetCode: 'lv-address-eh', searchType: 'autocomplete'},
  {country: 'Madagascar', iso3Code: 'MDG', iso2Code: 'MG', datasetCode: 'mo-address-ed', searchType: 'singleline, validate'},
  {country: 'Madagascar', iso3Code: 'MDG', iso2Code: 'MG', datasetCode: 'mo-address-eh', searchType: 'autocomplete'},
  {country: 'Malawi', iso3Code: 'MWI', iso2Code: 'MW', datasetCode: 'mw-address-ed', searchType: 'singleline, validate'},
  {country: 'Malawi', iso3Code: 'MWI', iso2Code: 'MW', datasetCode: 'mw-address-eh', searchType: 'autocomplete'},
  {country: 'Malaysia', iso3Code: 'MYS', iso2Code: 'MY', datasetCode: 'my-address-ed', searchType: 'singleline, validate'},
  {country: 'Malaysia', iso3Code: 'MYS', iso2Code: 'MY', datasetCode: 'my-address-eh', searchType: 'autocomplete'},
  {country: 'Maldives', iso3Code: 'MDV', iso2Code: 'MV', datasetCode: 'mv-address-ed', searchType: 'singleline, validate'},
  {country: 'Maldives', iso3Code: 'MDV', iso2Code: 'MV', datasetCode: 'mv-address-eh', searchType: 'autocomplete'},
  {country: 'Mali', iso3Code: 'MLI', iso2Code: 'ML', datasetCode: 'ml-address-ed', searchType: 'singleline, validate'},
  {country: 'Mali', iso3Code: 'MLI', iso2Code: 'ML', datasetCode: 'ml-address-eh', searchType: 'autocomplete'},
  {country: 'Malta', iso3Code: 'MLT', iso2Code: 'MT', datasetCode: 'mt-address-ed', searchType: 'singleline, validate'},
  {country: 'Malta', iso3Code: 'MLT', iso2Code: 'MT', datasetCode: 'mt-address-eh', searchType: 'autocomplete'},
  {country: 'Marshall Islands', iso3Code: 'MHL', iso2Code: 'MH', datasetCode: 'mh-address-eh', searchType: 'autocomplete'},
  {country: 'Martinique', iso3Code: 'MTQ', iso2Code: 'MQ', datasetCode: 'mq-address-eh', searchType: 'autocomplete'},
  {country: 'Mauritania', iso3Code: 'MRT', iso2Code: 'MR', datasetCode: 'mr-address-ed', searchType: 'singleline, validate'},
  {country: 'Mauritania', iso3Code: 'MRT', iso2Code: 'MR', datasetCode: 'mr-address-eh', searchType: 'autocomplete'},
  {country: 'Mauritius', iso3Code: 'MUS', iso2Code: 'MU', datasetCode: 'mu-address-ed', searchType: 'singleline, validate'},
  {country: 'Mauritius', iso3Code: 'MUS', iso2Code: 'MU', datasetCode: 'mu-address-eh', searchType: 'autocomplete'},
  {country: 'Mayotte', iso3Code: 'MYT', iso2Code: 'YT', datasetCode: 'yt-address-eh', searchType: 'autocomplete'},
  {country: 'Mexico', iso3Code: 'MEX', iso2Code: 'MX', datasetCode: 'mx-address-ed', searchType: 'singleline, validate'},
  {country: 'Mexico', iso3Code: 'MEX', iso2Code: 'MX', datasetCode: 'mx-address-eh', searchType: 'autocomplete'},
  {country: 'Micronesia, Federated States of', iso3Code: 'FSM', iso2Code: 'FM', datasetCode: 'fm-address-eh', searchType: 'autocomplete'},
  {country: 'Moldova', iso3Code: 'MDA', iso2Code: 'MD', datasetCode: 'md-address-ed', searchType: 'singleline, validate'},
  {country: 'Moldova', iso3Code: 'MDA', iso2Code: 'MD', datasetCode: 'md-address-eh', searchType: 'autocomplete'},
  {country: 'Monaco', iso3Code: 'MCO', iso2Code: 'MC', datasetCode: 'mc-address-ed', searchType: 'singleline, validate'},
  {country: 'Monaco', iso3Code: 'MCO', iso2Code: 'MC', datasetCode: 'mc-address-eh', searchType: 'autocomplete'},
  {country: 'Mongolia', iso3Code: 'MNG', iso2Code: 'MN', datasetCode: 'mn-address-ed', searchType: 'singleline, validate'},
  {country: 'Mongolia', iso3Code: 'MNG', iso2Code: 'MN', datasetCode: 'mn-address-eh', searchType: 'autocomplete'},
  {country: 'Montenegro', iso3Code: 'MNE', iso2Code: 'ME', datasetCode: 'me-address-ed', searchType: 'singleline, validate'},
  {country: 'Montenegro', iso3Code: 'MNE', iso2Code: 'ME', datasetCode: 'me-address-eh', searchType: 'autocomplete'},
  {country: 'Montserrat', iso3Code: 'MSR', iso2Code: 'MS', datasetCode: 'ms-address-eh', searchType: 'autocomplete'},
  {country: 'Morocco', iso3Code: 'MAR', iso2Code: 'MA', datasetCode: 'ma-address-ed', searchType: 'singleline, validate'},
  {country: 'Morocco', iso3Code: 'MAR', iso2Code: 'MA', datasetCode: 'ma-address-eh', searchType: 'autocomplete'},
  {country: 'Mozambique', iso3Code: 'MOZ', iso2Code: 'MZ', datasetCode: 'mz-address-ed', searchType: 'singleline, validate'},
  {country: 'Mozambique', iso3Code: 'MOZ', iso2Code: 'MZ', datasetCode: 'mz-address-eh', searchType: 'autocomplete'},
  {country: 'Myanmar', iso3Code: 'MMR', iso2Code: 'MM', datasetCode: 'mm-address-ed', searchType: 'singleline, validate'},
  {country: 'Myanmar', iso3Code: 'MMR', iso2Code: 'MM', datasetCode: 'mm-address-eh', searchType: 'autocomplete'},
  {country: 'Namibia', iso3Code: 'NAM', iso2Code: 'NA', datasetCode: 'na-address-ed', searchType: 'singleline, validate'},
  {country: 'Namibia', iso3Code: 'NAM', iso2Code: 'NA', datasetCode: 'na-address-eh', searchType: 'autocomplete'},
  {country: 'Nauru', iso3Code: 'NRU', iso2Code: 'NR', datasetCode: 'nr-address-ed', searchType: 'singleline, validate'},
  {country: 'Nauru', iso3Code: 'NRU', iso2Code: 'NR', datasetCode: 'nr-address-eh', searchType: 'autocomplete'},
  {country: 'Nepal', iso3Code: 'NPL', iso2Code: 'NP', datasetCode: 'np-address-ed', searchType: 'singleline, validate'},
  {country: 'Nepal', iso3Code: 'NPL', iso2Code: 'NP', datasetCode: 'np-address-eh', searchType: 'autocomplete'},
  {country: 'Netherlands', iso3Code: 'NLD', iso2Code: 'NL', datasetCode: 'nl-address-ed', searchType: 'singleline, validate'},
  {country: 'Netherlands', iso3Code: 'NLD', iso2Code: 'NL', datasetCode: 'nl-address-eh', searchType: 'autocomplete'},
  {country: 'New Caledonia', iso3Code: 'NCL', iso2Code: 'NC', datasetCode: 'nc-address-eh', searchType: 'autocomplete'},
  {country: 'New Zealand DataFusion', iso3Code: 'NZL', iso2Code: 'NZ', datasetCode: 'nz-additional-datafusion', searchType: 'autocomplete, singleline, typedown, validate'},
  {country: 'New Zealand Postal Address', iso3Code: 'NZL', iso2Code: 'NZ', datasetCode: 'nz-address', searchType: 'autocomplete, singleline, typedown, validate'},
  {country: 'Nicaragua', iso3Code: 'NIC', iso2Code: 'NI', datasetCode: 'ni-address-ed', searchType: 'singleline, validate'},
  {country: 'Nicaragua', iso3Code: 'NIC', iso2Code: 'NI', datasetCode: 'ni-address-eh', searchType: 'autocomplete'},
  {country: 'Niger', iso3Code: 'NER', iso2Code: 'NE', datasetCode: 'ne-address-ed', searchType: 'singleline, validate'},
  {country: 'Niger', iso3Code: 'NER', iso2Code: 'NE', datasetCode: 'ne-address-eh', searchType: 'autocomplete'},
  {country: 'Nigeria', iso3Code: 'NGA', iso2Code: 'NG', datasetCode: 'ng-address-ed', searchType: 'singleline, validate'},
  {country: 'Nigeria', iso3Code: 'NGA', iso2Code: 'NG', datasetCode: 'ng-address-eh', searchType: 'autocomplete'},
  {country: 'Niue', iso3Code: 'NIU', iso2Code: 'NU', datasetCode: 'nu-address-ed', searchType: 'singleline, validate'},
  {country: 'Norfolk Island', iso3Code: 'NFK', iso2Code: 'NF', datasetCode: 'nf-address-ed', searchType: 'singleline, validate'},
  {country: 'Norfolk Island', iso3Code: 'NFK', iso2Code: 'NF', datasetCode: 'nf-address-eh', searchType: 'autocomplete'},
  {country: 'North Macedonia', iso3Code: 'MKD', iso2Code: 'MK', datasetCode: 'mk-address-ed', searchType: 'singleline, validate'},
  {country: 'North Macedonia', iso3Code: 'MKD', iso2Code: 'MK', datasetCode: 'mk-address-eh', searchType: 'autocomplete'},
  {country: 'Northern Mariana Islands', iso3Code: 'MNP', iso2Code: 'MP', datasetCode: 'mp-address-eh', searchType: 'autocomplete'},
  {country: 'Norway', iso3Code: 'NOR', iso2Code: 'NO', datasetCode: 'no-address-ed', searchType: 'validate'},
  {country: 'Norway', iso3Code: 'NOR', iso2Code: 'NO', datasetCode: 'no-address-eh', searchType: 'autocomplete'},
  {country: 'Oman', iso3Code: 'OMN', iso2Code: 'OM', datasetCode: 'om-address-ed', searchType: 'singleline, validate'},
  {country: 'Oman', iso3Code: 'OMN', iso2Code: 'OM', datasetCode: 'om-address-eh', searchType: 'autocomplete'},
  {country: 'Pakistan', iso3Code: 'PAK', iso2Code: 'PK', datasetCode: 'pk-address-ed', searchType: 'singleline, validate'},
  {country: 'Pakistan', iso3Code: 'PAK', iso2Code: 'PK', datasetCode: 'pk-address-eh', searchType: 'autocomplete'},
  {country: 'Palau', iso3Code: 'PLW', iso2Code: 'PW', datasetCode: 'pw-address-eh', searchType: 'autocomplete'},
  {country: 'Palestine', iso3Code: 'PSE', iso2Code: 'PS', datasetCode: 'ps-address-eh', searchType: 'autocomplete'},
  {country: 'Panama', iso3Code: 'PAN', iso2Code: 'PA', datasetCode: 'pa-address-ed', searchType: 'singleline, validate'},
  {country: 'Panama', iso3Code: 'PAN', iso2Code: 'PA', datasetCode: 'pa-address-eh', searchType: 'autocomplete'},
  {country: 'Papua New Guinea', iso3Code: 'PNG', iso2Code: 'PG', datasetCode: 'pg-address-ed', searchType: 'singleline, validate'},
  {country: 'Papua New Guinea', iso3Code: 'PNG', iso2Code: 'PG', datasetCode: 'pg-address-eh', searchType: 'autocomplete'},
  {country: 'Paraguay', iso3Code: 'PRY', iso2Code: 'PY', datasetCode: 'py-address-ed', searchType: 'singleline, validate'},
  {country: 'Paraguay', iso3Code: 'PRY', iso2Code: 'PY', datasetCode: 'py-address-eh', searchType: 'autocomplete'},
  {country: 'Peru', iso3Code: 'PER', iso2Code: 'PE', datasetCode: 'pe-address-ed', searchType: 'singleline, validate'},
  {country: 'Peru', iso3Code: 'PER', iso2Code: 'PE', datasetCode: 'pe-address-eh', searchType: 'autocomplete'},
  {country: 'Philippines', iso3Code: 'PHL', iso2Code: 'PH', datasetCode: 'ph-address-ed', searchType: 'singleline, validate'},
  {country: 'Philippines', iso3Code: 'PHL', iso2Code: 'PH', datasetCode: 'ph-address-eh', searchType: 'autocomplete'},
  {country: 'Pitcairn Islands', iso3Code: 'PCN', iso2Code: 'PN', datasetCode: 'pn-address-eh', searchType: 'autocomplete'},
  {country: 'Poland', iso3Code: 'POL', iso2Code: 'PL', datasetCode: 'pl-address-ed', searchType: 'singleline, validate'},
  {country: 'Poland', iso3Code: 'POL', iso2Code: 'PL', datasetCode: 'pl-address-eh', searchType: 'autocomplete'},
  {country: 'Portugal', iso3Code: 'PRT', iso2Code: 'PT', datasetCode: 'pt-address-ed', searchType: 'singleline, validate'},
  {country: 'Portugal', iso3Code: 'PRT', iso2Code: 'PT', datasetCode: 'pt-address-eh', searchType: 'autocomplete'},
  {country: 'Qatar', iso3Code: 'QAT', iso2Code: 'QA', datasetCode: 'qa-address-ed', searchType: 'singleline, validate'},
  {country: 'Qatar', iso3Code: 'QAT', iso2Code: 'QA', datasetCode: 'qa-address-eh', searchType: 'autocomplete'},
  {country: 'Romania', iso3Code: 'ROU', iso2Code: 'RO', datasetCode: 'ro-address-ed', searchType: 'singleline, validate'},
  {country: 'Romania', iso3Code: 'ROU', iso2Code: 'RO', datasetCode: 'ro-address-eh', searchType: 'autocomplete'},
  {country: 'Russian Federation', iso3Code: 'RUS', iso2Code: 'RU', datasetCode: 'ru-address-ed', searchType: 'singleline, validate'},
  {country: 'Russian Federation', iso3Code: 'RUS', iso2Code: 'RU', datasetCode: 'ru-address-eh', searchType: 'autocomplete'},
  {country: 'Rwanda', iso3Code: 'RWA', iso2Code: 'RW', datasetCode: 'rw-address-ed', searchType: 'singleline, validate'},
  {country: 'Rwanda', iso3Code: 'RWA', iso2Code: 'RW', datasetCode: 'rw-address-eh', searchType: 'autocomplete'},
  {country: 'Réunion', iso3Code: 'REU', iso2Code: 'RE', datasetCode: 're-address-eh', searchType: 'autocomplete'},
  {country: 'Saint Barthélemy', iso3Code: 'BLM', iso2Code: 'BL', datasetCode: 'bl-address-eh', searchType: 'autocomplete'},
  {country: 'Saint Helena', iso3Code: 'SHN', iso2Code: 'SH', datasetCode: 'sh-address-eh', searchType: 'autocomplete'},
  {country: 'Saint Kitts and Nevis', iso3Code: 'KNA', iso2Code: 'KN', datasetCode: 'kn-address-ed', searchType: 'singleline, validate'},
  {country: 'Saint Kitts and Nevis', iso3Code: 'KNA', iso2Code: 'KN', datasetCode: 'kn-address-eh', searchType: 'autocomplete'},
  {country: 'Saint Lucia', iso3Code: 'LCA', iso2Code: 'LC', datasetCode: 'lc-address-eh', searchType: 'autocomplete'},
  {country: 'Saint Pierre and Miquelon', iso3Code: 'SPM', iso2Code: 'PM', datasetCode: 'pm-address-eh', searchType: 'autocomplete'},
  {country: 'Saint Vincent and the Grenadines', iso3Code: 'VCT', iso2Code: 'VC', datasetCode: 'vc-address-ed', searchType: 'singleline, validate'},
  {country: 'Saint Vincent and the Grenadines', iso3Code: 'VCT', iso2Code: 'VC', datasetCode: 'vc-address-eh', searchType: 'autocomplete'},
  {country: 'Samoa', iso3Code: 'WSM', iso2Code: 'WS', datasetCode: 'ws-address-ed', searchType: 'singleline, validate'},
  {country: 'Samoa', iso3Code: 'WSM', iso2Code: 'WS', datasetCode: 'ws-address-eh', searchType: 'autocomplete'},
  {country: 'San Marino', iso3Code: 'SMR', iso2Code: 'SM', datasetCode: 'sm-address-ed', searchType: 'singleline, validate'},
  {country: 'San Marino', iso3Code: 'SMR', iso2Code: 'SM', datasetCode: 'sm-address-eh', searchType: 'autocomplete'},
  {country: 'Sao Tome and Principe', iso3Code: 'STP', iso2Code: 'ST', datasetCode: 'st-address-ed', searchType: 'singleline, validate'},
  {country: 'Sao Tome and Principe', iso3Code: 'STP', iso2Code: 'ST', datasetCode: 'st-address-eh', searchType: 'autocomplete'},
  {country: 'Saudi Arabia', iso3Code: 'SAU', iso2Code: 'SA', datasetCode: 'sa-address-ed', searchType: 'singleline, validate'},
  {country: 'Saudi Arabia', iso3Code: 'SAU', iso2Code: 'SA', datasetCode: 'sa-address-eh', searchType: 'autocomplete'},
  {country: 'Senegal', iso3Code: 'SEN', iso2Code: 'SN', datasetCode: 'sn-address-ed', searchType: 'singleline, validate'},
  {country: 'Senegal', iso3Code: 'SEN', iso2Code: 'SN', datasetCode: 'sn-address-eh', searchType: 'autocomplete'},
  {country: 'Serbia', iso3Code: 'SRB', iso2Code: 'RS', datasetCode: 'rs-address-ed', searchType: 'singleline, validate'},
  {country: 'Serbia', iso3Code: 'SRB', iso2Code: 'RS', datasetCode: 'rs-address-eh', searchType: 'autocomplete'},
  {country: 'Seychelles', iso3Code: 'SYC', iso2Code: 'SC', datasetCode: 'sc-address-ed', searchType: 'singleline, validate'},
  {country: 'Seychelles', iso3Code: 'SYC', iso2Code: 'SC', datasetCode: 'sc-address-eh', searchType: 'autocomplete'},
  {country: 'Sierra Leone', iso3Code: 'SLE', iso2Code: 'SL', datasetCode: 'sl-address-ed', searchType: 'singleline, validate'},
  {country: 'Sierra Leone', iso3Code: 'SLE', iso2Code: 'SL', datasetCode: 'sl-address-eh', searchType: 'autocomplete'},
  {country: 'Singapore', iso3Code: 'SGP', iso2Code: 'SG', datasetCode: 'sg-address', searchType: 'autocomplete, singleline, typedown, validate'},
  {country: 'Sint Maarten', iso3Code: 'SXM', iso2Code: 'SX', datasetCode: 'sx-address-ed', searchType: 'singleline, validate'},
  {country: 'Sint Maarten', iso3Code: 'SXM', iso2Code: 'SX', datasetCode: 'sx-address-eh', searchType: 'autocomplete'},
  {country: 'Slovakia', iso3Code: 'SVK', iso2Code: 'SK', datasetCode: 'sk-address-ed', searchType: 'singleline, validate'},
  {country: 'Slovakia', iso3Code: 'SVK', iso2Code: 'SK', datasetCode: 'sk-address-eh', searchType: 'autocomplete'},
  {country: 'Slovenia', iso3Code: 'SVN', iso2Code: 'SI', datasetCode: 'si-address-ed', searchType: 'singleline, validate'},
  {country: 'Slovenia', iso3Code: 'SVN', iso2Code: 'SI', datasetCode: 'si-address-eh', searchType: 'autocomplete'},
  {country: 'Solomon Islands', iso3Code: 'SLB', iso2Code: 'SB', datasetCode: 'sb-address-ed', searchType: 'singleline, validate'},
  {country: 'Solomon Islands', iso3Code: 'SLB', iso2Code: 'SB', datasetCode: 'sb-address-eh', searchType: 'autocomplete'},
  {country: 'Somalia', iso3Code: 'SOM', iso2Code: 'SO', datasetCode: 'so-address-ed', searchType: 'singleline, validate'},
  {country: 'Somalia', iso3Code: 'SOM', iso2Code: 'SO', datasetCode: 'so-address-eh', searchType: 'autocomplete'},
  {country: 'South Africa', iso3Code: 'ZAF', iso2Code: 'ZA', datasetCode: 'za-address-ed', searchType: 'singleline, validate'},
  {country: 'South Africa', iso3Code: 'ZAF', iso2Code: 'ZA', datasetCode: 'za-address-eh', searchType: 'autocomplete'},
  {country: 'South Georgia and the South Sandwich Islands', iso3Code: 'SGS', iso2Code: 'GS', datasetCode: 'gs-address-eh', searchType: 'autocomplete'},
  {country: 'South Sudan', iso3Code: 'SSD', iso2Code: 'SS', datasetCode: 'ss-address-ed', searchType: 'singleline, validate'},
  {country: 'South Sudan', iso3Code: 'SSD', iso2Code: 'SS', datasetCode: 'ss-address-eh', searchType: 'autocomplete'},
  {country: 'Spain', iso3Code: 'ESP', iso2Code: 'ES', datasetCode: 'es-address', searchType: 'singleline, typedown'},
  {country: 'Spain', iso3Code: 'ESP', iso2Code: 'ES', datasetCode: 'es-address-ed', searchType: 'validate'},
  {country: 'Spain', iso3Code: 'ESP', iso2Code: 'ES', datasetCode: 'es-address-eh', searchType: 'autocomplete'},
  {country: 'Sri Lanka', iso3Code: 'LKA', iso2Code: 'LK', datasetCode: 'lk-address-ed', searchType: 'singleline, validate'},
  {country: 'Sudan', iso3Code: 'SDN', iso2Code: 'SD', datasetCode: 'sd-address-ed', searchType: 'singleline, validate'},
  {country: 'Sudan', iso3Code: 'SDN', iso2Code: 'SD', datasetCode: 'sd-address-eh', searchType: 'autocomplete'},
  {country: 'Suriname', iso3Code: 'SUR', iso2Code: 'SR', datasetCode: 'sr-address-ed', searchType: 'singleline, validate'},
  {country: 'Suriname', iso3Code: 'SUR', iso2Code: 'SR', datasetCode: 'sr-address-eh', searchType: 'autocomplete'},
  {country: 'Svalbard and Jan Mayen Islands', iso3Code: 'SJM', iso2Code: 'SJ', datasetCode: 'sj-address-eh', searchType: 'autocomplete'},
  {country: 'Swaziland (Eswatini)', iso3Code: 'SWZ', iso2Code: 'SZ', datasetCode: 'sz-address-ed', searchType: 'singleline, validate'},
  {country: 'Swaziland (Eswatini)', iso3Code: 'SWZ', iso2Code: 'SZ', datasetCode: 'sz-address-eh', searchType: 'autocomplete'},
  {country: 'Sweden', iso3Code: 'SWE', iso2Code: 'SE', datasetCode: 'se-address', searchType: 'singleline, typedown'},
  {country: 'Sweden', iso3Code: 'SWE', iso2Code: 'SE', datasetCode: 'se-address-ed', searchType: 'validate'},
  {country: 'Sweden', iso3Code: 'SWE', iso2Code: 'SE', datasetCode: 'se-address-eh', searchType: 'autocomplete'},
  {country: 'Switzerland', iso3Code: 'CHE', iso2Code: 'CH', datasetCode: 'ch-address', searchType: 'singleline, typedown'},
  {country: 'Switzerland', iso3Code: 'CHE', iso2Code: 'CH', datasetCode: 'ch-address-ed', searchType: 'validate'},
  {country: 'Switzerland', iso3Code: 'CHE', iso2Code: 'CH', datasetCode: 'ch-address-eh', searchType: 'autocomplete'},
  {country: 'Syria', iso3Code: 'SYR', iso2Code: 'SY', datasetCode: 'sy-address-ed', searchType: 'singleline, validate'},
  {country: 'Syria', iso3Code: 'SYR', iso2Code: 'SY', datasetCode: 'sy-address-eh', searchType: 'autocomplete'},
  {country: 'Taiwan', iso3Code: 'TWN', iso2Code: 'TW', datasetCode: 'tw-address-ed', searchType: 'singleline, validate'},
  {country: 'Taiwan', iso3Code: 'TWN', iso2Code: 'TW', datasetCode: 'tw-address-eh', searchType: 'autocomplete'},
  {country: 'Tajikistan', iso3Code: 'TJK', iso2Code: 'TJ', datasetCode: 'tj-address-ed', searchType: 'singleline, validate'},
  {country: 'Tajikistan', iso3Code: 'TJK', iso2Code: 'TJ', datasetCode: 'tj-address-eh', searchType: 'autocomplete'},
  {country: 'Tanzania', iso3Code: 'TZA', iso2Code: 'TZ', datasetCode: 'tz-address-ed', searchType: 'singleline, validate'},
  {country: 'Tanzania', iso3Code: 'TZA', iso2Code: 'TZ', datasetCode: 'tz-address-eh', searchType: 'autocomplete'},
  {country: 'Thailand', iso3Code: 'THA', iso2Code: 'TH', datasetCode: 'th-address-ed', searchType: 'singleline, validate'},
  {country: 'Thailand', iso3Code: 'THA', iso2Code: 'TH', datasetCode: 'th-address-eh', searchType: 'autocomplete'},
  {country: 'Timor-Leste', iso3Code: 'TLS', iso2Code: 'TL', datasetCode: 'tl-address-ed', searchType: 'singleline, validate'},
  {country: 'Timor-Leste', iso3Code: 'TLS', iso2Code: 'TL', datasetCode: 'tl-address-eh', searchType: 'autocomplete'},
  {country: 'Togo', iso3Code: 'TGO', iso2Code: 'TG', datasetCode: 'tg-address-ed', searchType: 'singleline, validate'},
  {country: 'Togo', iso3Code: 'TGO', iso2Code: 'TG', datasetCode: 'tg-address-eh', searchType: 'autocomplete'},
  {country: 'Tokelau', iso3Code: 'TKL', iso2Code: 'TK', datasetCode: 'tk-address-ed', searchType: 'singleline, validate'},
  {country: 'Tokelau', iso3Code: 'TKL', iso2Code: 'TK', datasetCode: 'tk-address-eh', searchType: 'autocomplete'},
  {country: 'Tonga', iso3Code: 'TON', iso2Code: 'TO', datasetCode: 'to-address-ed', searchType: 'singleline, validate'},
  {country: 'Tonga', iso3Code: 'TON', iso2Code: 'TO', datasetCode: 'to-address-eh', searchType: 'autocomplete'},
  {country: 'Trinidad and Tobago', iso3Code: 'TTO', iso2Code: 'TT', datasetCode: 'tt-address-ed', searchType: 'singleline, validate'},
  {country: 'Trinidad and Tobago', iso3Code: 'TTO', iso2Code: 'TT', datasetCode: 'tt-address-eh', searchType: 'autocomplete'},
  {country: 'Tunisia', iso3Code: 'TUN', iso2Code: 'TN', datasetCode: 'tn-address-ed', searchType: 'singleline, validate'},
  {country: 'Tunisia', iso3Code: 'TUN', iso2Code: 'TN', datasetCode: 'tn-address-eh', searchType: 'autocomplete'},
  {country: 'Turkey', iso3Code: 'TUR', iso2Code: 'TR', datasetCode: 'tr-address-ed', searchType: 'singleline, validate'},
  {country: 'Turkey', iso3Code: 'TUR', iso2Code: 'TR', datasetCode: 'tr-address-eh', searchType: 'autocomplete'},
  {country: 'Turkish Republic of Northern Cyprus', iso3Code: 'NCY', iso2Code: 'CY', datasetCode: 'ny-address-eh', searchType: 'autocomplete'},
  {country: 'Turkmenistan', iso3Code: 'TKM', iso2Code: 'TM', datasetCode: 'tm-address-ed', searchType: 'singleline, validate'},
  {country: 'Turkmenistan', iso3Code: 'TKM', iso2Code: 'TM', datasetCode: 'tm-address-eh', searchType: 'autocomplete'},
  {country: 'Turks and Caicos Islands', iso3Code: 'TCA', iso2Code: 'TC', datasetCode: 'tc-address-eh', searchType: 'autocomplete'},
  {country: 'Tuvalu', iso3Code: 'TUV', iso2Code: 'TV', datasetCode: 'tv-address-ed', searchType: 'singleline, validate'},
  {country: 'Tuvalu', iso3Code: 'TUV', iso2Code: 'TV', datasetCode: 'tv-address-eh', searchType: 'autocomplete'},
  {country: 'Uganda', iso3Code: 'UGA', iso2Code: 'UG', datasetCode: 'ug-address-ed', searchType: 'singleline, validate'},
  {country: 'Uganda', iso3Code: 'UGA', iso2Code: 'UG', datasetCode: 'ug-address-eh', searchType: 'autocomplete'},
  {country: 'Ukraine', iso3Code: 'UKR', iso2Code: 'UA', datasetCode: 'ua-address-ed', searchType: 'singleline, validate'},
  {country: 'Ukraine', iso3Code: 'UKR', iso2Code: 'UA', datasetCode: 'ua-address-eh', searchType: 'autocomplete'},
  {country: 'United Arab Emirates', iso3Code: 'ARE', iso2Code: 'AE', datasetCode: 'ae-address-ed', searchType: 'singleline, validate'},
  {country: 'United Arab Emirates', iso3Code: 'ARE', iso2Code: 'AE', datasetCode: 'ae-address-eh', searchType: 'autocomplete'},
  {country: 'United Kingdom', iso3Code: 'GBR', iso2Code: 'GB', datasetCode: ['gb-address'], searchType: 'autocomplete'},
  {country: 'United Kingdom', iso3Code: 'GBR', iso2Code: 'GB', datasetCode: ['gb-additional-business', 'gb-additional-multipleresidence', 'gb-additional-notyetbuilt'], searchType: 'singleline'},
  {country: 'United Kingdom AddressBase Premium', iso3Code: 'GBR', iso2Code: 'GB', datasetCode: 'gb-address-addressbase', searchType: 'singleline, typedown, validate, lookup'},
  {country: 'United Kingdom AddressBase Premium with Islands', iso3Code: 'GBR', iso2Code: 'GB', datasetCode: 'gb-additional-addressbaseislands', searchType: 'singleline, typedown, validate, lookup'},
  {country: 'United Kingdom Business Names', iso3Code: 'GBR', iso2Code: 'GB', datasetCode: 'gb-additional-business', searchType: 'singleline, typedown'},
  {country: 'United Kingdom Electricity', iso3Code: 'GBR', iso2Code: 'GB', datasetCode: 'gb-additional-electricity', searchType: 'singleline, typedown, validate, lookup'},
  {country: 'United Kingdom Gas', iso3Code: 'GBR', iso2Code: 'GB', datasetCode: 'gb-additional-gas', searchType: 'singleline, typedown, validate, lookup'},
  {country: 'United Kingdom Street Level', iso3Code: 'GBR', iso2Code: 'GB', datasetCode: 'gb-address-streetlevel', searchType: 'singleline, typedown'},
  {country: 'United Kingdom Extended Business', iso3Code: 'GBR', iso2Code: 'GB', datasetCode: 'gb-additional-businessextended', searchType: 'singleline, typedown, validate, lookup'},
  {country: 'United Kingdom Cymraeg (Welsh Language) Postal Address', iso3Code: 'GBR', iso2Code: 'GB', datasetCode: 'gb-address-wales', searchType: 'singleline, typedown, validate'},
  {country: 'United States of America', iso3Code: 'USA', iso2Code: 'US', datasetCode: 'us-address', searchType: 'autocomplete, singleline, typedown, validate'},
  {country: 'Uruguay', iso3Code: 'URY', iso2Code: 'UY', datasetCode: 'uy-address-ed', searchType: 'singleline, validate'},
  {country: 'Uruguay', iso3Code: 'URY', iso2Code: 'UY', datasetCode: 'uy-address-eh', searchType: 'autocomplete'},
  {country: 'Uzbekistan', iso3Code: 'UZB', iso2Code: 'UZ', datasetCode: 'uz-address-ed', searchType: 'singleline, validate'},
  {country: 'Uzbekistan', iso3Code: 'UZB', iso2Code: 'UZ', datasetCode: 'uz-address-eh', searchType: 'autocomplete'},
  {country: 'Vanuatu', iso3Code: 'VUT', iso2Code: 'VU', datasetCode: 'vu-address-ed', searchType: 'singleline, validate'},
  {country: 'Vanuatu', iso3Code: 'VUT', iso2Code: 'VU', datasetCode: 'vu-address-eh', searchType: 'autocomplete'},
  {country: 'Vatican City (Holy See)', iso3Code: 'VAT', iso2Code: 'VA', datasetCode: 'va-address-ed', searchType: 'singleline, validate'},
  {country: 'Vatican City (Holy See)', iso3Code: 'VAT', iso2Code: 'VA', datasetCode: 'va-address-eh', searchType: 'autocomplete'},
  {country: 'Venezuela', iso3Code: 'VEN', iso2Code: 'VE', datasetCode: 've-address-ed', searchType: 'singleline, validate'},
  {country: 'Venezuela', iso3Code: 'VEN', iso2Code: 'VE', datasetCode: 've-address-eh', searchType: 'autocomplete'},
  {country: 'Viet Nam', iso3Code: 'VNM', iso2Code: 'VN', datasetCode: 'vn-address-ed', searchType: 'singleline, validate'},
  {country: 'Viet Nam', iso3Code: 'VNM', iso2Code: 'VN', datasetCode: 'vn-address-eh', searchType: 'autocomplete'},
  {country: 'Virgin Islands, British', iso3Code: 'VGB', iso2Code: 'VG', datasetCode: 'vg-address-eh', searchType: 'autocomplete'},
  {country: 'Virgin Islands, U.S.', iso3Code: 'VIR', iso2Code: 'VI', datasetCode: 'vi-address-eh', searchType: 'autocomplete'},
  {country: 'Wallis and Futuna Islands', iso3Code: 'WLF', iso2Code: 'WF', datasetCode: 'wf-address-eh', searchType: 'autocomplete'},
  {country: 'Western Sahara', iso3Code: 'ESH', iso2Code: 'EH', datasetCode: 'eh-address-ed', searchType: 'singleline, validate'},
  {country: 'Western Sahara', iso3Code: 'ESH', iso2Code: 'EH', datasetCode: 'eh-address-eh', searchType: 'autocomplete'},
  {country: 'Yemen', iso3Code: 'YEM', iso2Code: 'YE', datasetCode: 'ye-address-ed', searchType: 'singleline, validate'},
  {country: 'Yemen', iso3Code: 'YEM', iso2Code: 'YE', datasetCode: 'ye-address-eh', searchType: 'autocomplete'},
  {country: 'Zambia', iso3Code: 'ZMB', iso2Code: 'ZM', datasetCode: 'zm-address-ed', searchType: 'singleline, validate'},
  {country: 'Zambia', iso3Code: 'ZMB', iso2Code: 'ZM', datasetCode: 'zm-address-eh', searchType: 'autocomplete'},
  {country: 'Zimbabwe', iso3Code: 'ZWE', iso2Code: 'ZW', datasetCode: 'zw-address-ed', searchType: 'singleline, validate'},
  {country: 'Zimbabwe', iso3Code: 'ZWE', iso2Code: 'ZW', datasetCode: 'zw-address-eh', searchType: 'autocomplete'}
];